<template>
 <div class="order_list main_top" >
        <div class="list_title">{{ $t('key93') }}
            <div class="icon_back flex_center iconfont icon-back-line-free hidden-sm-and-up" @click="toBack()"></div>
        </div>
        <div class="list_content" >
            <div class="list_empty flex_col_center" v-if="!orderList.length">
                <div class="empty_icon"><img src="../../assets/image/empty.png" alt=""></div>
                <div class="empty_text">{{ $t('key104') }}</div>
            </div>
            <div class="list_has" v-else >
                <div class="list_thumb">
                    <div class="list_group " v-for="(item,index) in orderList" :key="index">
                        <div class="order_top flex_sb">
                            <div class="order_num flex">
                                <span class="order_tit">{{ $t('key105') }}:</span>
                                <span>{{item.orderNo}}</span>
                            </div>
                        </div>
                        <div class="order_content flex">
                            <div class="order_img"><img :src="item.image" alt=""></div>
                            <div class="order_info">
                                <div class="order_title">{{ item.name }}</div>
                                <div class="price_group flex">
                                    <div class="price_show"><span>{{ currency.remark }}</span>{{ item.unitPrice }}</div>
                                    <div class="price_org">{{ currency.remark }} {{ item.amount }}</div>
                                    <div class="count">X {{ item.quantity }}</div>
                                </div>
                                <div class="order_status flex_sb">
                                    
                                    <div class="status_name">
                                        <div class="status_item" v-if="item.status==1">{{ $t('key106') }}</div>  
                                        <div class="status_item" v-if="item.status==0">{{ $t('key103') }}</div>  
                                        <div class="status_item" v-if="item.status==2">{{ $t('key109') }}</div>
                                        <div class="status_item" v-if="item.status==3">{{ $t('key100') }}</div>
                                    </div>
                                    <div class="status_time">
                                        <span class="time_name">{{ $t('key107') }}:</span>
                                        <span>{{ item.createTime }}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { divide } from '../../utils/tool';
export default {
    components:{
    //    homeHeader,
    },
    data(){
        return{
            /*  
            支付状态  status 0=未支付,1=已支付,2=支付失败,3=支付处理中
            订单状态 orderStatus 4=已创建,5=已确认,6=交付中,7=已关闭,8=已完成
            */

            tabOptions:[
                {label:'All',name:'first',status:-1,orderStatus:-1},
                {label:'Unpaid',name:'second',status:'0'},
                {label:'In processing',name:'third',status:3}, 
                {label:'Paid',name:'fourth',status:1},
                {label:'Payment failed',name:'five',status:2},
                {label:'created',name:'six',orderStatus:4,status:1},
                {label:'Confirmed',name:'seven',orderStatus:5,status:1},
                {label:'Delivering',name:'eight',orderStatus:6,status:1},
                {label:'Closed',name:'nine',orderStatus:7,status:1},
                {label:'Completed',name:'ten',orderStatus:8,status:1},
                {label:'Refunding',name:'tenOne',orderStatus:9,status:1},
                {label:'Refund completed',name:'tenWwo',orderStatus:10,status:1},  
            ],
            activeName:'first',
            orderList:[],
            pageIndex:1,
            pageSize:20,
            status:-1,
            orderStatus:-1,
            isLastPage:false,
            }
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
            let pageIndex = this.pageIndex
            let pageSize = this.pageSize
            let status = this.status
            let orderStatus = this.orderStatus
            this.$axiosApi.getOrderList({pageIndex,pageSize,status,orderStatus}).then(res=>{
                if(res.code == 200){   
                    res.data.list.map(v=>{
                        if(!/^\s*$/.test(v.remark)){
                            v.remark = JSON.parse(v.remark)
                        }
                        v.unitPrice = divide(v.unitPrice)
                        v.amount = divide(v.amount)
                        this.orderList.push(v)
                        return v
                    })
                    // this.orderList = res.data.list
                    this.isLastPage = res.data.isLastPage
                    console.log('orderList',this.orderList)
                }
            })
        },
        toPage(path){
            this.$router.push(path)
        },
        toBack(){
          this.$router.back()
        },
        handleScroll() {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const bottomOfPage = windowHeight + scrollY >= documentHeight;   
            if (bottomOfPage && !this.isLastPage) {
                this.pageIndex = this.pageIndex+1
                this.getData()
            }         
        },
      

    },
    mounted() {
    window.addEventListener('scroll', this.handleScroll)  
   },
    computed:{
        currency(){ return this.$store.state.currency}
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>

<style lang="scss" scoped>
 .order_list{
    margin-top:16px;
    .list_title{
        width: 100%;
        height: 48px;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        color: #0f0f0f;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding-left:12px;
        text-align: left;
        line-height: 48px;
    }
    .list_content{
        .list_empty{
            height: 456px;
            border-radius: 4px;
            background:#fff;
            .empty_icon{
                width: 160px;
            } 
            .empty_text{
                margin-top: 8px;
                color: #77808c;
                font-size: 15px;
            }
        }
        .list_has{
            min-height: 456px;
            border-radius: 4px;
            background:#fff;
            .list_thumb{
                padding:30px;
                margin-bottom:16px;
                .list_group{
                    background:#eff2f5;
                    border-radius: 4px;
                    padding:16px;
                    margin-bottom:16px;
                    .order_top{
                        .order_num{
                            font-size: 12px;
                            color:#5e626b;
                            .order_tit{
                                font-weight: 600;
                                color:#0f0f0f;
                                margin-right:8px;
                            }
                        }
                    }
                    .order_content{
                        text-align: left;
                        margin-top:8px;
                        .order_img{
                            width: 80px;
                            height: 80px;
                            border-radius: 4px;
                            overflow: hidden;
                            
                            >img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        
                        .order_info{
                            margin-left:8px;
                            flex:1;
                            color:#0f0f0f;
                            .order_title{
                                font-size:15px;
                                line-height: 18px;
                                
                            }
                            .price_group{
                                font-size: 14px;
                                align-items: baseline;
                                .price_show{
                                    margin-right:8px;
                                    color:#ff5000;
                                    font-weight: 700;
                                    font-size:20px;
                                    >span{
                                        font-size: 12px;
                                    }
                                }
                                .price_org{
                                    font-size: 12px;
                                    color:#5e626b;
                                    text-decoration: line-through;
                                }
                                .count{
                                    color:#5e626b;
                                    margin-left:16px;
                                }
                            }
                            .order_status{
                                margin-top:4px;
                                .status_name{
                                    .status_item{
                                        padding:4px 14px;
                                        font-size: 12px;
                                        border-radius: 24px;
                                        border:1px solid #fc0;
                                        cursor: pointer;
                                    }
                                }
                                .status_time{
                                    color:#5e626b;
                                    font-size: 12px;
                                    .time_name{
                                        color:#0f0f0f;
                                        font-weight: 600;
                                        margin-right:12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:768px){
    .order_list{
        // padding-top:48px;
        margin-top: 0 !important;;
        .list_title{
          text-align: center;
          height: 44px;
          margin:0 -16px 12px!important;
          line-height: 44px;
          position: relative;
          width: auto;
         
          .icon_back{
            font-size: 20px;
            position: absolute;
            left:16px;
            top:12px;
            height: 20px;
            cursor: pointer;
          }
        }
    }
    .list_thumb{
        padding: 0 !important;
        background:#eff2f5;
        .list_group {
            background: #fff !important;
        }
    }
}
</style>